
















































import { Component, Mixins } from 'vue-property-decorator'
import fillStore from '@/services/fillStore'
import { ImageResolver, LocalStorageManager } from '@/services/Managers'
import Discord from '@/services/Connections/Discord'
import { IMixinConnections } from '@/types/interfaces'
import ProfileBackgrounds from '@/config/ProfileBackgrounds'

@Component
export default class ProfileHeader extends Mixins<IMixinConnections>(Discord) {
  currentBackground = ''
  fillStore = fillStore
  setDefaultAvatar = ImageResolver.setDefaultAvatar

  mounted() {
    const savedBackground = LocalStorageManager.getItem('OGG_Background') ?? 'blue'

    this.currentBackground = ProfileBackgrounds[savedBackground]
  }
}
