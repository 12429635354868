const profileBackgrounds = {
  'blue': require('@/assets/images/backgrounds/OGG_Background_Blue.png'),
  'lightblue': require('@/assets/images/backgrounds/OGG_Background_LightBlue.png'),
  'coralblue': require('@/assets/images/backgrounds/OGG_Background_CoralBlue.png'),
  'green': require('@/assets/images/backgrounds/OGG_Background_Green.png'),
  'red': require('@/assets/images/backgrounds/OGG_Background_Red.png'),
  'purple': require('@/assets/images/backgrounds/OGG_Background_Purple.png'),
  'yellow': require('@/assets/images/backgrounds/OGG_Background_Yellow.png'),
  'white': require('@/assets/images/backgrounds/OGG_Background_White.png'),
}

export default profileBackgrounds